var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12"},[_c('indexHeader',{attrs:{"title":_vm.$t('salesInstallments.SalesPremiums'),"description":_vm.$t('salesInstallments.FromHereYouCanControlTheSalesPremiums')}}),_c('indexTable',{attrs:{"searchText":_vm.$t('salesInstallments.SearchForAPremium'),"emptyTableText":_vm.$t('salesInstallments.TherAreNoSalesInstallments'),"emptyTableSubText":_vm.$t('allerts.TryAddingSomeItemsToTheTableAndTryAgain'),"buttonRole":_vm.$user.role.sales_cheques_add,"statusSearch":true,"statusValues":[
                      { title: _vm.$t('salesInstallments.situation'), value: '' },
                      { title: _vm.$t('salesInstallments.Waiting'), value: 0 },
                      { title: _vm.$t('salesInstallments.driven'), value: 1 } ],"cloumns":[
                       {column: 'code',    title: _vm.$t('salesInstallments.batch') ,type: 'mainLink' , sort: true },
                       {column: 'client_type',    title: _vm.$t('salesInstallments.CustomerName') ,type: 'clintLink', sort: true},
                       {column: 'created_date',    title: _vm.$t('salesInstallments.RegistrationDate') ,type: 'text' , sort: true },
                       {column: 'date',    title: _vm.$t('salesInstallments.paymentDate') ,type: 'text' , sort: true },
                       {column: 'description',    title: _vm.$t('salesInstallments.AndThatIsAbout') ,type: 'text' , sort: true },
                       {column: 'total',    title: _vm.$t('salesInstallments.theAmount') ,type: 'text' , sort: true },
                       {column: 'paid',    title: _vm.$t('salesInstallments.paid') ,type: 'text' , sort: true },
                       {column: 'stat',    title: _vm.$t('salesInstallments.active') ,type: 'status', sort: true,  values: [
                         {title: _vm.$t('salesInstallments.Waiting'), value: 1, color: 'info'},
                         {title: _vm.$t('salesInstallments.driven'), value: 0, color: 'success'} ]},
                       {column: 'options', title: _vm.$t('salesInstallments.Settings'), type: 'options', options: [
                           {name: 'show'},
                           {name: 'edit',   role: _vm.$user.admin || _vm.$user.role.sales_cheques_edit},
                           {name: 'file'},
                           {name: 'link',   role: _vm.$user.admin || _vm.$user.role.sales_cheques_edit, title:_vm.$t('salesInstallments.AddPayment'), icon: 'dollar-sign', link:'/salesInstallmentsPayments/create?sales_installment_id='},
                           {name: 'delete', role: _vm.$user.admin || _vm.$user.role.sales_cheques_delete} ]}
                     ],"deleteText":{
                      attention: _vm.$t('allerts.Attention'),
                      areYouReallySureToDeleteTheItem: _vm.$t('allerts.areYouReallySureToDeleteTheItem'),
                      close: _vm.$t('allerts.close'),
                      confirm: _vm.$t('allerts.confirm'),
                      loading: _vm.$t('allerts.loading'),
                     }}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }