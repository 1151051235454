<template>

  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-12">
        <indexHeader :title="$t('salesInstallments.SalesPremiums')"
                     :description="$t('salesInstallments.FromHereYouCanControlTheSalesPremiums')"/>
         <indexTable :searchText="$t('salesInstallments.SearchForAPremium')"
                     :emptyTableText="$t('salesInstallments.TherAreNoSalesInstallments')"
                     :emptyTableSubText="$t('allerts.TryAddingSomeItemsToTheTableAndTryAgain')"
                     :buttonRole="$user.role.sales_cheques_add"
                     :statusSearch="true"
                     :statusValues="[
                        { title: $t('salesInstallments.situation'), value: '' },
                        { title: $t('salesInstallments.Waiting'), value: 0 },
                        { title: $t('salesInstallments.driven'), value: 1 },
                     ]"
                     :cloumns="[
                         {column: 'code',    title: $t('salesInstallments.batch') ,type: 'mainLink' , sort: true },
                         {column: 'client_type',    title: $t('salesInstallments.CustomerName') ,type: 'clintLink', sort: true},
                         {column: 'created_date',    title: $t('salesInstallments.RegistrationDate') ,type: 'text' , sort: true },
                         {column: 'date',    title: $t('salesInstallments.paymentDate') ,type: 'text' , sort: true },
                         {column: 'description',    title: $t('salesInstallments.AndThatIsAbout') ,type: 'text' , sort: true },
                         {column: 'total',    title: $t('salesInstallments.theAmount') ,type: 'text' , sort: true },
                         {column: 'paid',    title: $t('salesInstallments.paid') ,type: 'text' , sort: true },
                         {column: 'stat',    title: $t('salesInstallments.active') ,type: 'status', sort: true,  values: [
                           {title: $t('salesInstallments.Waiting'), value: 1, color: 'info'},
                           {title: $t('salesInstallments.driven'), value: 0, color: 'success'},
                         ]},
                         {column: 'options', title: $t('salesInstallments.Settings'), type: 'options', options: [
                             {name: 'show'},
                             {name: 'edit',   role: $user.admin || $user.role.sales_cheques_edit},
                             {name: 'file'},
                             {name: 'link',   role: $user.admin || $user.role.sales_cheques_edit, title:$t('salesInstallments.AddPayment'), icon: 'dollar-sign', link:'/salesInstallmentsPayments/create?sales_installment_id='},
                             {name: 'delete', role: $user.admin || $user.role.sales_cheques_delete},
                         ]}
                       ]"
                     :deleteText="{
                        attention: $t('allerts.Attention'),
                        areYouReallySureToDeleteTheItem: $t('allerts.areYouReallySureToDeleteTheItem'),
                        close: $t('allerts.close'),
                        confirm: $t('allerts.confirm'),
                        loading: $t('allerts.loading'),
                       }" />
      </div>
    </div>
  </div>

</template>
<script>

  import indexHeader from '../elements/index/indexHeader.vue'
  import indexTable from '../elements/index/indexTable.vue'

  export default {
    data() {
      return {
        path: '/salesInstallments',
      }
    },
    components: {
      indexHeader,
      indexTable
    }
  }

</script>
